export const IS_DEV = process.env.NODE_ENV !== 'production'

export const API_VERSION = 'v1'

export const baseUrl = IS_DEV
  ? 'https://kjmis-dev-api.lenturin.com'
  : 'https://dev-api.kjmis.id'

export * from './storage'
export * from './formatDate'
export * from './formatNumber'
